#changePreferences {
  background-color: var(--color-white-800);
  color: '#000';
  border-radius: 5px;
  padding: 5px;
  font-weight: 500;
  margin-left: 10px;
}

#changePreferences:hover,
#btnOk {
  cursor: pointer;
}

#btnOk {
  background-color: var(--color-yellow);
  color: #000;
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 500;
}