.App-header {
    background-color: var(--color-primary);
    display: flex;
    padding: 0 26px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 1vmin);
    color: white;
    height: 10vmin;
}

.links-container {
    display: flex;
    padding: 0 10px;
}

.links {
    list-style: none;
}

.link {
    color: white;
    padding: 5px 10px;
    text-decoration: none;
}

.buy {
    background-color: var(--color-yellow);
    border-radius: 5px;
    color: black;
}

.buy:hover {
    transition: all 500ms;
    background-color: antiquewhite;
}

.App-logo {
    height: 5vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}