:root {
  --color-primary: #282c34;
  --color-secondary: rgb(254, 220, 50);
  --color-white: #ffffff;
  --color-white-800: rgb(234, 234, 234);
  --color-black: #000000;
  --color-yellow: rgb(254, 220, 50);
  --color-grey: #888888;
}

.font-family-kiwi {
  font-family: 'Kiwi Maru', serif;
}

.text-primary {
  color: var(--color-primary);
}

.text-secondary {
  color: var(--color-secondary);
}

.text-grey {
  color: var(--color-grey);
}

.text-white {
  color: var(--color-white);
}

.text-yellow {
  color: var(--color-yellow);
}

.text-black {
  color: var(--color-black);
}

.bg-primary {
  background-color: var(--color-primary);
}

.bg-secondary {
  background-color: var(--color-secondary);
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-size: calc(10px + 1vmin);
  font-family: 'Poppins', 'Open Sans', sans-serif;
}

.App {
  text-align: center;
}



.bar {
  display: flex;
}

#close {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: red;
}

#minimize {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: yellow;
}

#fullscreen {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: green;
}

.window {
  border: 1px solid black;
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
  justify-items: center;
}


.flex-row {
  flex-direction: row;
}

.flex-row-center {
  align-items: center;
}

.text-0 {
  font-size: calc(4px + 2vmin);
}

.text-1 {
  font-size: calc(6px + 2vmin);
}

.text-2 {
  font-size: calc(8px + 2vmin);
}

.text-3 {
  font-size: calc(10px + 2vmin);
}

.text-4 {
  font-size: calc(12px + 2vmin);
}

.text-5 {
  font-size: calc(14px + 2vmin);
}

.text-6 {
  font-size: calc(16px + 2vmin);
}

.mr-1 {
  margin-right: 2px;
}

.mr-2 {
  margin-right: 4px;
}

.mr-3 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.p-1 {
  padding: 2px;
}

.p-2 {
  padding: 4px;
}

.p-3 {
  padding: 8px;
}

.p-4 {
  padding: 16px;
}

.pr-1 {
  padding-right: 2px;
}

.pr-2 {
  padding-right: 4px;
}

.pr-3 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pl-1 {
  padding-left: 2px;
}

.pl-2 {
  padding-left: 4px;
}

.pl-3 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pt-1 {
  padding-top: 2px;
}

.pt-2 {
  padding-top: 4px;
}

.pt-3 {
  padding-top: 8px;
}

.pt-4 {
  padding-right: 16px;
}

.pb-1 {
  padding-bottom: 2px;
}

.pb-2 {
  padding-bottom: 4px;
}

.pb-3 {
  padding-bottom: 8px;
}

.pb-4 {
  padding-bottom: 16px;
}

.App-link {
  color: #61dafb;
}