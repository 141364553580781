#btn-check-post {
    color: #ccc;
    transition-timing-function: linear;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: underline;

}

#btn-check-post:hover {
    text-decoration: none;
    color: #000000;
    transition: all 500ms;

    background-color: var(--color-secondary);
    cursor: pointer;
}