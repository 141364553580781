.form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.input-email {
    font-size: large;
}

/* .btn {
    border-radius: 4px;
    border: 1px;
    padding: 4px 8px;
} */

.text-white {
    color: white;
}

.btn-submit {
    background-color: #282c34;
}

.btn:hover {
    cursor: pointer;
}