.post-title {
    text-align: center;
    font-weight: 700
}

.card {
    display: flex;
    flex-direction: column;
}

.card-avatar {}

.card-author {}

.card-bottom {
    display: flex;
    flex-direction: row;
}

.card-read-duration {}

.post-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid gray;
}